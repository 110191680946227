import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import HighchartsVue from "highcharts-vue";

export default defineNuxtPlugin(({ vueApp }) => {
  HighchartsNoData(Highcharts);
  HighchartsMore(Highcharts);
  Exporting(Highcharts);
  ExportData(Highcharts);
  vueApp.use(HighchartsVue);
});
